const black = 'rgb(0, 0, 0)';
const blue = 'rgb(58,124,179)';
const lightGray = 'rgb(152,152,152)';
const superLightGray = 'rgb(245,245,245)';

export {
  black,
  blue,
  lightGray,
  superLightGray
};
