import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import {black} from '../modules/colors';
import {media} from '../modules/util';

const Container = styled.div`
  padding: 0 20px;

  ${media.tablet`padding: 0 10px;`}

  a {
    color: ${black};
    opacity: 0.5;

    &:hover {
      opacity: 0.2;
    }
  }
`;

const MenuItem = ({
  children,
  link
}) => (
  <Container>
    <Link
      to={link}
    >
      {children}
    </Link>
  </Container>
);

MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired
};

export default MenuItem;
