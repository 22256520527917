/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import {StaticQuery, graphql} from 'gatsby';
import styled, {createGlobalStyle} from 'styled-components';
import {superLightGray} from '../modules/colors';
import Header from './Header';

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  body {
    margin: 0;
    padding: 0;
  }
  a, button {
    text-decoration: none;
    transition: opacity 0.5s ease 0s, filter 0.5s ease 0s;
  }
  * {
    font-family: 'Source Sans Pro', sans-serif;
  }
`;

const Main = styled.main`
  display: flex;
  justify-content: center;

  max-width: 1280px;
  margin: 0 auto;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100px;
  background-color: ${superLightGray};
`;

const Layout = ({children}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <GlobalStyle />
        <Header siteTitle={data.site.siteMetadata.title} />
        <Main>{children}</Main>
        <Footer>
          <div>
            Really.gr
            ©
            {' '}
            {new Date().getFullYear()}
          </div>
          <div>
            Sister sites:
            {' '}
            <a href="https://fashionway.gr">Fashionway.gr</a>
            {' '}
  -
            {' '}
            <a href="https://dearlife.gr">Dearlife.gr</a>
          </div>
        </Footer>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
