import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import MenuItem from './MenuItem';
import LogoImage from '../images/logo.jpg';
import {lightGray} from '../modules/colors';
import {media} from '../modules/util';

const Container = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 10px 0;
  border-bottom: 1px solid ${lightGray};
`;

const Logo = styled.div`
  img {
    height: 70px;
  }
`;

const Menu = styled.div`
  display: flex;
  margin-left: 20px;

  ${media.tablet`margin-left: 0px;`}
`;

const Header = ({siteTitle}) => (
  <Container>
    <Link to="/">
      <Logo>
        <img src={LogoImage} alt={siteTitle} />
      </Logo>
    </Link>
    <Menu>
      <MenuItem link="/">
        HOME
      </MenuItem>
      <MenuItem link="/category/news">
        NEWS
      </MenuItem>
      <MenuItem link="/category/style">
        STYLE
      </MenuItem>
    </Menu>
  </Container>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ''
};

export default Header;
