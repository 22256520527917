import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MenuItem from './MenuItem';
import {
  lightGray, black, superLightGray, blue
} from '../modules/colors';
import {media} from '../modules/util';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px;

  ${media.desktop`width: 40%;`}
  ${media.tablet`width: 100%;`}
`;

const Image = styled.img`
  width: 400px;
  height: 200px;
  margin-bottom: 10px;
  object-fit: cover;

  ${media.phone`width: 300px;`}
`;

const Title = styled.h1`
  text-align: center;
  max-width: 500px;
  margin: 10px 0;
  color: ${black};

  height: 60px;

  ${media.tablet`height: auto;`}
  ${media.phone`width: 100%;`}
`;

const ReadMore = styled.span`
  background-color: ${black};
  margin-top: 10px;
  border-radius: 4px;
  font-size: 12px;
  letter-spacing: 2px;

  &:hover {
    background-color: ${blue}
  }

  a {
    color: ${superLightGray};
    padding: 14px 30px;
    display: block;
  }
`;

const Footer = styled.div`
  border-top: 1px solid ${lightGray};
  display: flex;
  width: 100%;
  margin-top: 10px;
`;

const Date = styled.div`

`;

const PostExcerpt = ({
  title,
  category,
  image,
  date,
  path
}) => {
  const srcSet = image && image.properties.srcSet && image.properties.srcSet.join(',');
  const sizes = image && image.properties.sizes && image.properties.sizes.join(',');
  const src = image && image.properties.src;

  return (
    <Container>
      <Link to={path}>
        <Image srcset={srcSet} sizes={sizes} src={src} alt={title} loading="lazy" />
      </Link>
      <MenuItem link={`/category/${category.toLowerCase()}`}>
        {category.toUpperCase()}
      </MenuItem>
      <Link to={path}>
        <Title>
          {title}
        </Title>
      </Link>
      <ReadMore><Link to={path}>ΠΕΡΙΣΣΟΤΕΡΑ</Link></ReadMore>
      <Footer>
        <Date>{date}</Date>
      </Footer>
    </Container>
  );
};

PostExcerpt.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
};

export default PostExcerpt;
