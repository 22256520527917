import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import PostExcerpt from '../components/PostExcerpt';
import {black, lightGray} from '../modules/colors';

/**
 * Recursively searches all the html elements to find the first image
 * @param {Array} items An array with all the html elements of the markdown content
 */
const findImage = items => {
  let image;

  const search = elements => {
    for (let i = 0, length = elements.length; i < length; i++) {
      const element = elements[i];
      if (element.tagName === 'img') {
        image = element;
        break;
      } else if (element.children && element.children.length) {
        search(element.children);
      }
    }
  };

  search(items);

  return image;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Articles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const Pagination = styled.div`
  margin: 40px;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.button`
  padding: 20px;
  border: 1px solid ${lightGray};
  color: ${lightGray};
  background-color: white;
  cursor: pointer;

  &:hover {
    background-color: ${black};
  }
`;

const IndexPage = ({
  data,
  pageContext
}) => {
  const {allMarkdownRemark: {edges: posts}} = data;
  const {
    currentPage,
    totalPages,
    prefix,
    pageTitle
  } = pageContext;

  const newerPage = currentPage === 1 ? 1 : currentPage - 1;
  const olderPage = currentPage === totalPages ? currentPage : currentPage + 1;

  // only the homepage, instead of prefix, uses / for first page
  const baseUrl = prefix === '/page/' ? '/' : prefix;

  return (
    <Layout>
      <SEO title={pageTitle} keywords={['really', 'celebrities', 'greece']} />
      <Container>
        <Articles>
          {
            posts.map(edge => {
              const image = findImage(edge.node.htmlAst.children);
              const post = {
                ...edge.node.frontmatter,
                image
              };
              return (
                <PostExcerpt
                  key={`${post.title}${post.date}`}
                  title={post.title}
                  category={post.category}
                  image={post.image}
                  date={post.date}
                  path={post.path}
                />
              );
            })
          }
        </Articles>
        <Pagination>
          {
            currentPage !== totalPages && (
              <Link to={`${prefix}${olderPage}`}><Button>Older</Button></Link>
            )
          }
          {
            currentPage !== 1 && (
              <Link to={newerPage === 1 ? baseUrl : `${prefix}${newerPage}`}><Button>Newer</Button></Link>
            )
          }
        </Pagination>
      </Container>
    </Layout>
  );
};

IndexPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageContext: PropTypes.any.isRequired
};

export default IndexPage;
